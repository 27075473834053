/**
 * View Menu
 *
 * @description :: View menu, not used for now
 */
import { CustomElement } from "../../components/custom-element";

import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

import { BoardModel } from "../../models/board.js";
import { Collection } from "../../models/collection.js";

import { ViewBoard } from "./board/controller.js";

export class ViewMain extends CustomElement {
  currentTemplate = "default";
  constructor(options) {
    super();
    this.addPropreties(options);
    this.userId = Global.user.uid;
  }

  initView() {
    this.setStyle(style);
    this.setSharedSheets([Global.bulmaSheet, Global.mainSheet]);
    this.render(html, { locale: this.locale });

    this.bindElements([
      ["#board-list", "boardList"],
      ["#template-list", "templateList"],
      ["#input-create", "inputCreate"],
      ["#select-create", "selectCreate"],
      ["#button-create", "buttonCreate"],
      ["#card-show-create", "cardShowCreate"],
      ["#button-show-create", "buttonShowCreate"],
    ]);

    this.boardCollection = new Collection(
      `users/${this.userId}/boards`,
      Global.appData.database,
      (childKey, childData) => this.onBoardAdded(childKey, childData),
      "index"
    );
    this.templateCollection = new Collection(
      `templates`,
      Global.appData.database,
      (childKey, childData) => this.onTemplateAdded(childKey, childData),
      "index"
    );
  }
  onButtonShowCreate(event) {
    this.cardShowCreate.style.display = "block";
    this.buttonShowCreate.style.display = "none";
  }
  onButtonCreateClick(event) {
    Global.firebaseFunctions
      .httpsCallable("CopyNewBoard")({
        fromId: "template-" + this.currentTemplate,
        toId: Global.appData.generateUID(),
        userId: Global.user.uid,
        title: this.inputCreate.value,
      })
      .then((result) => {
        console.log(result);
      });

    this.cardShowCreate.style.display = "none";
    this.buttonShowCreate.style.display = "inline-flex";
  }

  onButtonThemeClick(event) {
    this.currentTemplate = event.target.dataset.templateName;
    this.mainRoot.querySelectorAll(".template-button").forEach((element) => {
      element.classList.remove("is-active");
    });

    event.target.classList.add("is-active");
  }

  onBoardAdded(childKey, childData) {
    var newBoardModel = new BoardModel(
      `users/${this.userId}/boards/${childKey}`,
      Global.appData.database,
      childData
    );
    var newView = new ViewBoard(this, newBoardModel, true);
    this.boardList.appendChild(newView);
  }

  onTemplateAdded(childKey, childData) {
    var newBoardModel = new BoardModel(
      `templates/${childKey}`,
      Global.appData.database,
      childData
    );
    var newView = new ViewBoard(this, newBoardModel, true);
    this.templateList.appendChild(newView);
  }

  disconnectedCallback() {}
}

customElements.define("view-main", ViewMain);
