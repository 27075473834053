import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

import { BoardItem } from "../../controller.js";

export class BoardItemDefault extends BoardItem {
  constructor(parent, model) {
    super(parent, model);
  }

  initTemplate() {
    this.setStyle(style);
    this.appendRender(
      html,
      {
        userPlan: Global.user,
        model: this.model.attributes,
        locale: this.locale,
      },
      this.mainRoot.querySelector(".container")
    );
    this.postitText = this.mainRoot.querySelector(".input-text");
    this.postitText.addEventListener("input", (event) =>
      this.onPostitTextChange(event)
    );
  }

  onPostitTextChange(event) {
    this.model.attributes.text = this.postitText.value;
    this.model.saveModel();
  }
}

customElements.define("board-item", BoardItemDefault);
