/**
 * WheelItem Class
 *
 * @description ::
 */

import Mustache from "mustache";
import { CustomElement } from "../../../components/custom-element.js";

import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

import { Draggable } from "../plugins/draggable.js";

export class BoardItem extends CustomElement {
  isCreated = false;
  isShadowElement = true;

  constructor(parent, model) {
    super();

    this.classList.add("BoardItem");

    if (model) {
      this.model = model;
      this.parent = parent;
      this.model.onValueUpdated = (newValue) => this.onValueUpdated(newValue);
      this.model.onModelDeleted = () => this.onDelete();
    }
  }

  initView() {
    this.setStyle(style);
    this.setSharedSheets([Global.bulmaSheet, Global.mainSheet]);
    this.render(html, {
      userPlan: Global.user,
      model: this.model.attributes,
      locale: this.locale,
    });
    this.updateRender();

    this.bindElements([[".button-menu", "menuButton"]]);
    this.setDraggable();
    this.setMenu();
    this.initTemplate();
  }

  emitUpdate(type) {
    this.parent.dispatchEvent(
      new CustomEvent("elementupdate", {
        bubbles: true,
        detail: { postitElement: this, updateType: type },
      })
    );
  }

  setMenu() {
    this.menuButton.addEventListener("click", (event) => this.spawnMenu(event));
  }

  spawnMenu(event) {
    let parentBound = this.parent.itemsList.getBoundingClientRect();
    let itemBound = this.getBoundingClientRect();

    let targetX = event.clientX;
    let targetY = event.clientY;
    let elWidth = this.offsetWidth;
    let elHeight = this.offsetHeight;
    let isSpawnRight = false;
    let isSpawnUp = false;

    if (targetX + elWidth + 100 > this.parent.itemsList.offsetWidth) {
      isSpawnRight = true;
    }

    if (targetY + elHeight + 100 > this.parent.itemsList.offsetHeight) {
      isSpawnUp = true;
    }

    this.parent.boardItemMenu.spawnAt(
      targetX,
      targetY,
      this,
      isSpawnRight,
      isSpawnUp
    );
  }

  setDraggable() {
    this.draggable = new Draggable(
      this,
      this.mainRoot.querySelector(".drag-handle"),
      this.parent.itemsList,
      this.onDragging.bind(this),
      this.onEndDrag.bind(this)
    );
  }

  onDragging(targetX, targetY) {
    let ghostX =
      targetX + this.mainRoot.querySelector(".drag-handle").offsetLeft;
    let ghostY =
      targetY +
      this.mainRoot.querySelector(".drag-handle").offsetTop +
      this.mainRoot.querySelector(".drag-handle").offsetHeight / 2;

    this.parent.layout.showGhostAt(ghostX, ghostY, this.model.attributes.size);
  }

  onEndDrag(targetX, targetY) {
    let ghostX =
      targetX + this.mainRoot.querySelector(".drag-handle").offsetLeft;
    let ghostY =
      targetY +
      this.mainRoot.querySelector(".drag-handle").offsetTop +
      this.mainRoot.querySelector(".drag-handle").offsetHeight / 2;
    this.parent.layout.moveItemAt(this, ghostX, ghostY);
  }

  onValueUpdated(newValue) {
    this.updateRender();
  }

  onDelete() {
    this.emitUpdate("removed");
    this.remove();
  }

  updateRender() {
    this.setAttributesFromModel(
      [
        "color",
        "template",
        "variant",
        "size",
        "icon",
        "wheelPosition",
        "itemDirection",
        "wheelQuarter",
        "isMainTimeline",
        "isWheelTimeline",
        "timelineRotation",
      ],
      this.model
    );
    this.emitUpdate("updaterender");
  }
}
