/**
 * Layout Class
 *
 * @description ::
 */

import { TimelineBackground } from "./background.js";

export class TimelineLayout {
  columnWidth = 0;
  rowHeight = 150;
  paddingLeft = 40;

  gridWidth = 0;
  gridHeight = 0;
  pixelWidth = 0;
  pixelHeight = 0;

  offsetTop = 0;

  itemsArray = [];
  gridArray = [];
  gridIndexesArray = [];

  currentGridPosition = null;

  timelines = null;

  constructor(container, elementName, config) {
    this.elementName = elementName;
    this.container = container;
    this.config = config;
    this.timelines = config.timelines;
    this.columnWidth = this.timelines[0].columnWidth;

    this.ghostEl = document.createElement("div");
    this.ghostEl.classList.add("ghost-postit");
    this.container.appendChild(this.ghostEl);
    this.backgroundEl = document.createElement("div");
    this.backgroundEl.classList.add("background-canvas");
    this.container.appendChild(this.backgroundEl);
    this.createTimelinesBackground();
  }

  createTimelinesBackground() {
    this.background = new TimelineBackground(
      this.backgroundEl,
      this.elementName,
      this
    );
  }

  createEmptyGridArray() {
    let newGridArray = new Array(this.gridHeight);

    for (var i = 0; i < newGridArray.length; i++) {
      newGridArray[i] = new Array(this.gridWidth);
      for (var j = 0; j < this.gridWidth; j++) {
        newGridArray[i][j] = null;
      }
    }

    return newGridArray;
  }

  updateGrid() {
    this.items = Array.from(this.container.querySelectorAll(this.elementName));
    if (this.items.length == 0) {
      var maxX = 0;
    } else {
      var maxX = this.items.reduce((prev, current) =>
        prev.model.attributes.positionX > current.model.attributes.positionX
          ? prev
          : current
      );
      this.gridWidth = maxX.model.attributes.positionX + 2;
    }

    this.gridHeight = this.timelines.length + 1;
    this.gridArray = this.createEmptyGridArray();
    this.gridIndexesArray = this.createEmptyGridArray();

    this.items.forEach((element) => {
      if (element.model) {
        for (let offset = 0; offset < element.model.attributes.size; offset++) {
          this.gridIndexesArray[element.model.attributes.positionY][
            element.model.attributes.positionX + offset
          ] = element;
        }

        this.gridArray[element.model.attributes.positionY][
          element.model.attributes.positionX
        ] = element;
      }
    });
  }

  countFreeCellsAfter(elementX, elementY) {
    let array = this.gridArray[elementY];
    let freeCellCounter = 0;
    for (let index = elementX + 1; index < array.length; index++) {
      const element = array[index];
      if (!element) {
        freeCellCounter++;
      } else {
        break;
      }
    }

    return freeCellCounter;
  }

  update() {
    this.updateGrid();

    this.totalHeight = this.getTotalHeight();
    this.offsetTop =
      (this.container.parentNode.offsetHeight - this.totalHeight) / 2;
    if (this.offsetTop < 0) this.offsetTop = 0;
    this.container.style.minWidth = this.gridWidth * this.columnWidth + "px";
    this.container.style.minHeight =
      this.container.parentNode.offsetHeight + "px";
    console.log(
      this.offsetTop,
      this.totalHeight + this.offsetTop,
      this.container.parentNode.scrollHeight
    );
    this.container.style.height = this.totalHeight + this.offsetTop + "px";
    this.container.style.width = this.container.parentNode.scrollWidth + "px";

    this.items.forEach((element) => {
      element.style.top =
        this.getRowY(element.model.attributes.positionY) +
        this.timelines[element.model.attributes.positionY].padding +
        "px";
      element.style.left =
        element.model.attributes.positionX * this.columnWidth +
        this.timelines[element.model.attributes.positionY].padding +
        "px";
      element.style.height =
        this.timelines[element.model.attributes.positionY].rowHeight -
        this.timelines[element.model.attributes.positionY].padding * 2 +
        "px";
      if (this.timelines[element.model.attributes.positionY].isFullWidth) {
        let countFreeCell = this.countFreeCellsAfter(
          element.model.attributes.positionX,
          element.model.attributes.positionY
        );

        element.style.width =
          this.columnWidth -
          this.timelines[element.model.attributes.positionY].padding * 2 +
          (this.columnWidth -
            this.timelines[element.model.attributes.positionY].padding * 2) *
            countFreeCell +
          "px";
      } else {
        element.style.width =
          this.columnWidth * element.model.attributes.size -
          this.timelines[element.model.attributes.positionY].padding * 2 +
          "px";
      }
      element.dataset.layoutstyle =
        this.timelines[element.model.attributes.positionY].layoutStyle;
      element.dataset.itemlayout = "timeline";
    });
    this.background.update();
  }

  showGhostAt(x, y, size) {
    let ghostPosition = this.getGridPositionAt(x + this.columnWidth / 2, y);
    this.ghostEl.style.display = "block";
    this.ghostEl.style.top =
      this.getRowY(ghostPosition.y) +
      this.timelines[ghostPosition.y].padding +
      "px";
    this.ghostEl.style.left = ghostPosition.x * this.columnWidth + "px";

    this.ghostEl.style.width = this.columnWidth * size + "px";
    this.ghostEl.style.height =
      this.timelines[ghostPosition.y].rowHeight -
      this.timelines[ghostPosition.y].padding * 2 +
      "px";
  }

  getBoundingSize(x, y) {
    let cellPosition = this.getGridPositionAt(x, y);
    let cellY = this.getRowY(cellPosition.y);
    let cellX = cellPosition.x * this.columnWidth;
    let cursorX = x - cellX;
    let cursorY = y - cellY;

    let paddingX = this.timelines[cellPosition.y].padding;
    let paddingY = this.timelines[cellPosition.y].padding;
    let margin = this.timelines[cellPosition.y].padding;

    let resultX = "center";
    if (cursorX < paddingX) resultX = "left";
    if (cursorX > this.columnWidth - paddingX) resultX = "right";
    let resultY = "center";

    let marginX, marginY, marginWidth, marginHeight;

    switch (resultX) {
      case "left":
        marginY =
          this.getRowY(cellPosition.y) + this.timelines[cellPosition.y].padding;
        marginX = cellPosition.x * this.columnWidth - margin;

        marginWidth = margin * 2;
        marginHeight =
          this.timelines[cellPosition.y].rowHeight -
          this.timelines[cellPosition.y].padding * 2;
        break;
      case "right":
        marginY =
          this.getRowY(cellPosition.y) + this.timelines[cellPosition.y].padding;
        marginX =
          cellPosition.x * this.columnWidth + (this.columnWidth - margin);

        marginWidth = margin * 2;
        marginHeight =
          this.timelines[cellPosition.y].rowHeight -
          this.timelines[cellPosition.y].padding * 2;
        break;
      case "center":
        marginY =
          this.getRowY(cellPosition.y) + this.timelines[cellPosition.y].padding;
        marginX = cellPosition.x * this.columnWidth;

        marginWidth = this.columnWidth;
        marginHeight =
          this.timelines[cellPosition.y].rowHeight -
          this.timelines[cellPosition.y].padding * 2;
        break;
      default:
        break;
    }
    return {
      hitDirectionX: resultX,
      hitDirectionY: resultY,
      x: marginX,
      y: marginY,
      width: marginWidth,
      height: marginHeight,
    };
  }

  getGridPositionAt(x, y) {
    let positionX = this.getColIndex(x);
    let positionY = this.getRowIndex(y - this.offsetTop);
    return { x: positionX, y: positionY };
  }

  moveItemAt(item, x, y) {
    this.ghostEl.style.display = "none";

    let newPosition = this.getGridPositionAt(x + this.columnWidth / 2, y);
    let positionX = newPosition.x;
    let positionY = newPosition.y;

    let target = this.gridArray[positionY][positionX];
    if (!target) {
      item.model.attributes.positionX = positionX;
      item.model.attributes.positionY = positionY;
      item.model.attributes.timelineRotation = "center";

      if (this.timelines[positionY].isMainTimeline) {
        item.model.attributes.isMainTimeline = true;
      } else {
        item.model.attributes.isMainTimeline = false;
      }

      if (positionY > 2) {
        item.model.attributes.timelineRotation = "top";
      }

      if (positionY < 2) {
        item.model.attributes.timelineRotation = "bottom";
      }

      item.model.saveModel();
      this.update();
    } else {
      let tempX = item.model.attributes.positionX;
      let tempY = item.model.attributes.positionY;
      item.model.attributes.positionX = positionX;
      item.model.attributes.positionY = positionY;

      target.model.attributes.positionX = tempX;
      target.model.attributes.positionY = tempY;

      item.model.saveModel();
      target.model.saveModel();

      this.update();
    }
  }

  getColIndex(x) {
    return Math.floor(x / this.columnWidth);
  }

  getRowIndex(y) {
    let result = 0;
    let tresholdY = 0;

    for (let index = 0; index < this.timelines.length; index++) {
      const row = this.timelines[index];
      let newTresholdY = tresholdY + row.rowHeight;
      if (y >= tresholdY && y < newTresholdY) {
        result = index;
      }
      tresholdY = newTresholdY;
    }

    if (y >= tresholdY) {
      result = this.timelines.length - 1;
    }

    return result;
  }

  getRowY(targetIndex) {
    let result = 0;
    let tresholdY = 0;

    for (let index = 0; index < this.timelines.length; index++) {
      const row = this.timelines[index];
      if (index == targetIndex) {
        result = tresholdY;
      }
      tresholdY += row.rowHeight;
    }

    return result + this.offsetTop;
  }

  getTotalHeight() {
    let result = 0;

    for (let index = 0; index < this.timelines.length; index++) {
      const row = this.timelines[index];
      result += row.rowHeight;
    }

    return result;
  }

  centerScroll() {
    this.container.parentNode.scroll(
      0,
      this.totalHeight / 2 - this.container.parentNode.offsetHeight / 2
    );
  }

  showMenutAt(x, y, menuElement) {
    let menuPosition = this.getGridPositionAt(x, y);
    this.currentGridPosition = menuPosition;
    menuElement.style.display = "block";
    menuElement.style.top =
      this.getRowY(menuPosition.y) +
      this.timelines[menuPosition.y].padding +
      "px";
    menuElement.style.left =
      menuPosition.x * this.columnWidth + this.columnWidth / 2 - 40 + "px";
  }

  remove() {
    this.ghostEl.remove();
    this.backgroundEl.remove();
    this.background.remove();
  }
}
