/**
 * WheeLayout Class
 *
 * @description ::
 */
import { WheelBackground } from "./background.js";

export class WheelLayout {
  itemsArray = [];

  currentWheelAngle = 0;
  currentWheelPosition = "outside";
  currentRingIndex = 0;

  constructor(container, elementName, config) {
    this.elementName = elementName;
    this.container = container;
    this.config = config;

    this.ghostEl = document.createElement("div");
    this.ghostEl.classList.add("ghost-item");
    this.ghostEl.style.display = "none";
    this.container.appendChild(this.ghostEl);

    this.backgroundEl = document.createElement("div");
    this.backgroundEl.classList.add("background-canvas");
    this.container.appendChild(this.backgroundEl);

    this.createBackground();
    this.updateWheel();
    this.centerScroll();
  }

  createBackground() {
    this.background = new WheelBackground(
      this.backgroundEl,
      this.elementName,
      this
    );
  }

  updateBackground() {
    this.background.update();
  }

  centerScroll() {
    this.container.parentNode.scroll(
      this.getWheelCenter().x - this.container.parentNode.offsetWidth / 2,
      this.getWheelCenter().y - this.container.parentNode.offsetHeight / 2
    );
  }

  updateWheel() {
    this.container.style.minWidth =
      this.config.rings[0].radius * 2 +
      this.config.rings[0].itemWidth * 2 +
      "px";
    this.container.style.width = this.container.parentNode.scrollWidth + "px";
    this.container.style.minHeight = this.config.rings[0].radius * 2 + "px";

    this.updateBackground();

    this.items = Array.from(this.container.querySelectorAll(this.elementName));

    this.items.forEach((item) => {
      item.dataset.itemlayout = "wheel";
      let distance = this.config.rings[this.config.rings.length - 1].radius;
      let offsetTop = 0;
      let offsetDistance = 0;
      if (!this.config.rings[item.model.attributes.ringIndex].isWheelTimeline) {
        item.style.width =
          this.config.rings[item.model.attributes.ringIndex].itemWidth + "px";
        item.style.height =
          this.config.rings[item.model.attributes.ringIndex].itemHeight + "px";

        distance = this.config.rings[item.model.attributes.ringIndex].radius;
        item.style.transformOrigin = null;
        item.style.transform = null;
      } else {
        offsetTop = 0;
        item.style.width = this.config.insideItemWidth + "px";
        item.style.height = this.config.insideItemHeight + "px";
        distance = this.config.rings[this.config.rings.length - 1].radius;
        var angle = 0;

        if (this.config.insideFreeRotate) {
          //    offsetDistance=10;

          switch (item.model.attributes.wheelQuarter) {
            case "left-top":
              item.style.transformOrigin = "center left";
              var angle = 180 * (Math.PI / 180);

              break;
            case "left-bottom":
              item.style.transformOrigin = "center left";
              var angle = 180 * (Math.PI / 180);
              break;
            case "right-top":
              item.style.transformOrigin = "center right";
              var angle = 0;

              break;
            case "right-bottom":
              item.style.transformOrigin = "center right";
              var angle = 0;
              break;
            default:
              break;
          }

          item.style.transform =
            "rotate(" + (item.model.attributes.wheelAngle + angle) + "rad)";
        }

        if (this.config.insideRotate) {
          //    offsetDistance=10;
          var insideAngle = 10;
          switch (item.model.attributes.wheelQuarter) {
            case "left-top":
              item.style.transformOrigin = "center left";
              var angle = insideAngle;

              break;
            case "left-bottom":
              item.style.transformOrigin = "center left";
              var angle = -insideAngle;
              break;
            case "right-top":
              item.style.transformOrigin = "center right";
              var angle = -insideAngle;

              break;
            case "right-bottom":
              item.style.transformOrigin = "center right";
              var angle = insideAngle;
              break;
            default:
              break;
          }

          item.style.transform =
            "rotate(" + (item.model.attributes.wheelAngle + angle) + "deg)";
        } else {
          item.style.transform = null;
        }
      }

      let newX = this.getPositionFromAngle(
        item.model.attributes.wheelAngle,
        distance + offsetDistance
      ).x;
      let newY = this.getPositionFromAngle(
        item.model.attributes.wheelAngle,
        distance + offsetDistance
      ).y;
      var offsetPos = item.offsetHeight / 2;

      if (item.model.attributes.itemDirection == "left") {
        item.style.left = newX - item.offsetWidth + offsetPos + "px";
        item.style.top = newY - offsetPos - offsetTop + "px";
      } else {
        item.style.left = newX - offsetPos + "px";
        item.style.top = newY - offsetPos - offsetTop + "px";
      }
    });
  }

  update() {
    this.updateWheel();
  }

  getWheelCenter() {
    return {
      x: this.container.scrollWidth / 2,
      y: this.container.scrollHeight / 2,
    };
  }

  getPositionFromAngle(angle, distance) {
    return {
      x: this.getWheelCenter().x + distance * Math.cos(angle),
      y: this.getWheelCenter().y + distance * Math.sin(angle),
    };
  }

  getWheelQuarter(x, y) {
    let quarter = "";
    if (x > this.getWheelCenter().x) {
      quarter += "right-";
    } else {
      quarter += "left-";
    }

    if (y > this.getWheelCenter().y) {
      quarter += "bottom";
    } else {
      quarter += "top";
    }

    return quarter;
  }

  isInsideWheel(x, y) {
    let r = this.config.rings[this.config.rings.length - 1].radius;
    let distanceFromCenter =
      (x - this.getWheelCenter().x) * (x - this.getWheelCenter().x) +
      (y - this.getWheelCenter().y) * (y - this.getWheelCenter().y);
    r *= r;
    if (distanceFromCenter < r) {
      return true;
    }
    return false;
  }

  getAngleFromPosition(x, y) {
    var deltaX = x - this.getWheelCenter().x;
    var deltaY = y - this.getWheelCenter().y;
    var ringIndex = this.getRingIndexFromPosition(x, y);
    var snapAngle = 0;

    if (this.config.rings[ringIndex].isWheelTimeline) {
      snapAngle = Math.PI / this.config.insideSnapAngle;
    } else {
      snapAngle = Math.PI / this.config.rings[ringIndex].snapAngle;
    }

    var angleValue =
      snapAngle * Math.floor(Math.atan2(deltaY, deltaX) / snapAngle);

    if (angleValue < -(Math.PI / 2)) {
      angleValue = Math.PI * 2 - Math.abs(angleValue);
    }

    return angleValue;
  }

  getDistanceFromCenter(x, y) {
    var deltaX = x - this.getWheelCenter().x;
    var deltaY = y - this.getWheelCenter().y;

    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }

  getRingIndexFromPosition(x, y) {
    var ringIndex = this.config.rings.length - 1;
    var distance = this.getDistanceFromCenter(x, y);

    for (let index = 0; index < this.config.rings.length; index++) {
      if (distance > this.config.rings[index].radius) {
        ringIndex = index;
        break;
      }
    }

    return ringIndex;
  }

  showMenutAt(x, y, menuElement) {
    let quarter = this.getWheelQuarter(x, y);
    let distance = 0;

    distance =
      this.config.rings[this.getRingIndexFromPosition(x, y)].radius + 25;

    var insideAngle = Math.PI / 4;

    switch (quarter) {
      case "left-top":
        var angle = -insideAngle * 3;

        break;
      case "left-bottom":
        var angle = insideAngle * 3;
        break;
      case "right-top":
        var angle = -insideAngle;

        break;
      case "right-bottom":
        var angle = insideAngle;
        break;
      default:
        break;
    }

    let ghostX = this.getPositionFromAngle(angle, distance).x;
    let ghostY = this.getPositionFromAngle(angle, distance).y;

    menuElement.style.top = ghostY - 25 + "px";
    menuElement.style.left = ghostX - 25 + "px";

    menuElement.style.display = "block";
    this.currentWheelAngle = angle;

    this.currentRingIndex = this.getRingIndexFromPosition(x, y);
  }

  showGhostAt(x, y, size) {
    let angle = this.getAngleFromPosition(x, y);
    let distance = 0;
    let quarter = this.getWheelQuarter(x, y);

    distance = this.config.rings[this.getRingIndexFromPosition(x, y)].radius;

    let ghostX = this.getPositionFromAngle(angle, distance).x;
    let ghostY = this.getPositionFromAngle(angle, distance).y;

    this.ghostEl.style.top = ghostY - 25 + "px";
    this.ghostEl.style.left = ghostX - 25 + "px";

    this.ghostEl.style.display = "block";

    this.ghostEl.style.width = 50 + "px";
    this.ghostEl.style.height = 50 + "px";
  }

  moveItemAt(item, x, y) {
    let angle = this.getAngleFromPosition(x, y);
    let isWheelTimeline = false;
    let quarter = this.getWheelQuarter(x, y);
    let ringIndex = this.getRingIndexFromPosition(x, y);

    if (this.config.rings[ringIndex].isWheelTimeline) {
      isWheelTimeline = true;
    }

    item.model.attributes.wheelAngle = angle;
    item.model.attributes.isWheelTimeline = isWheelTimeline;
    item.model.attributes.wheelQuarter = quarter;
    item.model.attributes.ringIndex = ringIndex;

    if (isWheelTimeline) {
      if (quarter == "left-top" || quarter == "left-bottom") {
        item.model.attributes.itemDirection = "right";
      } else {
        item.model.attributes.itemDirection = "left";
      }
    } else {
      if (quarter == "left-top" || quarter == "left-bottom") {
        item.model.attributes.itemDirection = "left";
      } else {
        item.model.attributes.itemDirection = "right";
      }
    }

    item.model.saveModel();
    this.update();

    this.ghostEl.style.display = "none";
  }

  remove() {
    this.ghostEl.remove();
    this.backgroundEl.remove();
    this.background.remove();
  }
}
