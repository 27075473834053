/**
 * View Tos
 *
 * @description :: View Terms Of Service
 */
import Mustache from "mustache";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewTos extends HTMLElement {

    constructor(options) {
        super();
        this.requestedUrl = options.requestedUrl;
        this.locale = options.locale;
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;

        this.appendChild(styleSheet);

    }

    connectedCallback() {
        this.initView(html);
    }

    initView(template) {
        this.innerHTML = Mustache.render(template, {locale:this.locale});


    }

   
    disconnectedCallback() {

    }

}

customElements.define('view-tos', ViewTos);