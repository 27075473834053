/**
 * WheelItemMenu
 *
 * @description :: View item menu
 */
import Mustache from "mustache";

import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

export class BoardItemMenu extends HTMLElement {
  constructor(parent) {
    super();
    this.id = "board-item-menu";
    this.parent = parent;
    this.locale = parent.locale;

    let styleSheet = document.createElement("style");
    styleSheet.textContent = style;
    this.appendChild(styleSheet);
  }

  connectedCallback() {
    this.initView(html);
  }

  initView(template) {
    this.innerHTML = Mustache.render(template, { locale: this.locale });

    this.querySelectorAll(".button-value").forEach((item) => {
      item.addEventListener("click", (event) => {
        this.onButtonClick(event);
      });
    });

    this.deleteButton = this.querySelector(".button-delete");
    this.deleteButton.addEventListener("click", (event) =>
      this.onButtonDeleteClick(event)
    );
    this.addEventListener("mouseleave", (event) => {
      this.hide();
    });
  }

  onButtonClick(event) {
    this.changePostitAttribute(
      event.target.dataset.attribute,
      event.target.dataset.value
    );
    this.hide();
  }

  changePostitAttribute(attribute, value) {
    this.currentItem.model.attributes[attribute] = value;
    this.currentItem.model.saveModel();
  }

  spawnAt(x, y, item, isSpawnRight, isSpawnUp) {
    console.log("spawning");
    this.currentItem = item;

    this.show();

    if (isSpawnRight) {
      this.style.left = x - this.getBoundingClientRect().width + "px";
    } else {
      this.style.left = x + "px";
    }

    if (isSpawnUp) {
      this.style.top = y - this.getBoundingClientRect().height + "px";
    } else {
      this.style.top = y + "px";
    }
  }

  onButtonDeleteClick(event) {
    this.currentItem.model.deleteModel();
    this.hide();
  }

  show() {
    this.style.display = "block";
  }

  hide() {
    this.style.display = "none";
  }

  disconnectedCallback() {}
}

customElements.define("board-item-menu", BoardItemMenu);
