/**
 * View MenuApp
 *
 * @description :: 
 */
import { CustomElement } from "../../components/custom-element";
import {Locale} from "../../locale.js";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewMenuApp extends CustomElement {

    constructor(options) {
        super();
        this.locale = new Locale()["fr"];
        this.addPropreties (options);
    }

    initView() {
        this.setStyle (style);
        this.setSharedSheets ([Global.bulmaSheet, Global.mainSheet]);
        this.render (html, {locale:this.locale});

        this.bindElements ([
            ['.menu-overlay', 'menuOverlay'],
            ['.logo-menu', 'logoMenu'],

        ]);

        this.logoMenu.addEventListener('click', event => this.toggleMenu());
        this.menuOverlay.addEventListener('click', event => this.toggleMenu());
    }

    toggleMenu () {
        this.menuOverlay.classList.toggle ("is-menu-hidden");
    }

    disconnectedCallback() {

    }

}

customElements.define('view-menuapp', ViewMenuApp);