import { Model } from "./model.js";

export class Collection {

    onChildAdded = null;

    constructor(referenceUrl, database, onChildAdded, orderBy) {
        this.onChildAdded = onChildAdded;
        this.reference = database.ref(referenceUrl).orderByChild(orderBy);

        this.reference.on('child_added', (snapshot) => {
            this.addChild(snapshot.key, snapshot.val());
        });

    }

    createChild(childData) {

    }

    addChild(childKey, childData) {
        this.onChildAdded(childKey, childData);
    }

    disconnect() {
        this.reference.off();
    }
}
