import Mustache from "mustache";

export class CustomElement extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.mainRoot = this.shadowRoot;
  }

  connectedCallback() {
    this.initView();
  }

  render(template, options) {
    if (this.shadowTemplateNode) {
      this.shadowTemplateNode.remove();
    }
    var shadowTemplate = document.createElement("template");
    shadowTemplate.innerHTML = Mustache.render(template, options);
    this.shadowTemplateNode = this.mainRoot.appendChild(
      shadowTemplate.content.cloneNode(true)
    );
  }

  appendRender(template, options, element) {
    var shadowTemplate = document.createElement("template");
    shadowTemplate.innerHTML = Mustache.render(template, options);
    element.appendChild(shadowTemplate.content.cloneNode(true));
  }

  setStyle(style) {
    let styleSheet = document.createElement("style");
    styleSheet.textContent = style;
    this.mainRoot.appendChild(styleSheet);
  }

  setSharedSheets(sheets) {
    this.mainRoot.adoptedStyleSheets = sheets;
  }

  setAttributesFromModel(attributes, model) {
    for (let index = 0; index < attributes.length; index++) {
      this.setAttribute(
        "data-" + attributes[index],
        model.attributes[attributes[index]]
      );
    }
  }

  addPropreties(propreties) {
    Object.assign(this, propreties);
  }

  bindElements(elements) {
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      this[elements[index][1]] = this.mainRoot.querySelector(
        elements[index][0]
      );
    }
  }
}
