/**
 * Navigator Class
 *
 * @description ::
 */

import Mustache from "mustache";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

import { Draggable } from "../plugins/draggable.js";

export class Navigator extends HTMLElement {

    isCreated = false;
    isShadowElement = true;
    zoomLevel = 10;

    constructor(container, elementName) {
        super();
        this.container = container;
        this.elementName = elementName;

        if (this.isShadowElement) {
            this.attachShadow({ mode: 'open' });
            this.mainRoot = this.shadowRoot;
        } else {
            this.mainRoot = this;
        }

        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;
        this.mainRoot.appendChild(styleSheet);

    }

    connectedCallback() {

        if (!this.isCreated) {
            this.initView(html);
            this.isCreated = true;
        }

    }

    initView(template) {

        var shadowTemplate = document.createElement('template');
        shadowTemplate.innerHTML = Mustache.render(template, {});
        this.shadowTemplateNode = this.mainRoot.appendChild(shadowTemplate.content.cloneNode(true));
        this.scrollViewport = this.mainRoot.querySelector('.scroll-viewport');
        this.previewItems = this.mainRoot.querySelector('.preview-items');
        this.draggable = new Draggable(this.scrollViewport, this.scrollViewport, this, this.onDragging.bind(this), this.onEndDrag.bind(this));
        window.addEventListener('resize', (event) => this.update(), false);

        this.container.addEventListener('scroll', event => {
            this.update();

        })
        this.update();

    }

    onDragging(x, y) {
        let containerWidth = this.container.scrollWidth;
        let containerHeight = this.container.scrollHeight;
        let viewportWidth = this.container.offsetWidth;
        let viewportHeight = this.container.offsetHeight;
        let viewportLeft = this.container.scrollLeft;
        let viewportTop = this.container.scrollTop;
        let maxX = containerWidth - viewportWidth;
        let maxY = containerHeight - viewportHeight;
        let min = 0;
        let newX = Math.min(Math.max((x * this.zoomLevel), min), maxX);
        let newY = Math.min(Math.max((y * this.zoomLevel), min), maxY);

        this.scrollViewport.style.left = (newX) / this.zoomLevel + "px";
        this.scrollViewport.style.top = (newY) / this.zoomLevel + "px";
        this.container.scrollLeft = newX;
        this.container.scrollTop = newY;
        this.skipUpdate = true;
    }

    onEndDrag(x, y) {

        let containerWidth = this.container.scrollWidth;
        let containerHeight = this.container.scrollHeight;
        let viewportWidth = this.container.offsetWidth;
        let viewportHeight = this.container.offsetHeight;
        let maxX = containerWidth - viewportWidth;
        let maxY = containerHeight - viewportHeight;
        let min = 0;
        let newX = Math.min(Math.max((x * this.zoomLevel), min), maxX);
        let newY = Math.min(Math.max((y * this.zoomLevel), min), maxY);

        this.scrollViewport.style.left = (newX) / this.zoomLevel + "px";
        this.scrollViewport.style.top = (newY) / this.zoomLevel + "px";
        this.container.scrollLeft = newX;
        this.container.scrollTop = newY;
        this.skipUpdate = true;
    }

    update() {
        if (this.skipUpdate) {
            this.skipUpdate = false;
        } else {
            let containerWidth = this.container.scrollWidth;
            let containerHeight = this.container.scrollHeight;
            let viewportWidth = this.container.offsetWidth;
            let viewportHeight = this.container.offsetHeight;
            let viewportLeft = this.container.scrollLeft;
            let viewportTop = this.container.scrollTop;

            this.style.width = (containerWidth) / this.zoomLevel + "px";
            this.style.height = (containerHeight) / this.zoomLevel + "px";
            this.scrollViewport.style.width = (viewportWidth) / this.zoomLevel + "px";
            this.scrollViewport.style.height = (viewportHeight) / this.zoomLevel + "px";
            this.scrollViewport.style.left = (viewportLeft) / this.zoomLevel + "px";
            this.scrollViewport.style.top = (viewportTop) / this.zoomLevel + "px";
        }


        this.items = this.container.querySelectorAll(this.elementName);
        this.previewItems.replaceChildren();
        this.items.forEach((element) => {

            let newDiv = document.createElement("div");
            let parentBound = this.container.getBoundingClientRect();
            let itemBound = element.getBoundingClientRect();
            let left = ((itemBound.left - parentBound.left) + this.container.scrollLeft) / this.zoomLevel;
            let top = ((itemBound.top - parentBound.top) + this.container.scrollTop) / this.zoomLevel;
            newDiv.style.top = top + "px";
            newDiv.style.left = left + "px";
            newDiv.style.position = "absolute";
            newDiv.style.width = element.offsetWidth / this.zoomLevel + "px";
            newDiv.style.height = element.offsetHeight / this.zoomLevel + "px";
            newDiv.classList.add(element.dataset.color);


            this.previewItems.appendChild(newDiv);

        });

    }


}

customElements.define('view-navigator', Navigator);