 import Mustache from "mustache";

 import firebase from "firebase/app";
 import "firebase/auth";
 import "firebase/database";

 class PaymentManager {

    constructor () {

    }
  
}

export default PaymentManager;