
export class Model {

    attributes = {};
    onValueUpdated = null;
    onModelDeleted = null;

    constructor(referenceUrl, database, attributes, create) {
        this.reference = database.ref(referenceUrl);
        if (create) {
            this.saveModel();
        }
        this.reference.on('value', (snapshot) => {
            if (!snapshot.val()) {
                this.onModelDelete();
            } else {

                Object.assign(this.attributes, snapshot.val());

                this.onDataUpdate();
            }
        });

    }

    saveModel() {
        this.reference.set(this.attributes);
    }

    saveValue(newValue) {
        this.reference.set(newValue);
    }

    deleteModel() {
        this.reference.remove();
    }

    onModelDelete() {
        if (this.onModelDeleted)
            this.onModelDeleted();
    }

    onDataUpdate() {
        if (this.onValueUpdated)
            this.onValueUpdated(this.attributes);
    }


}
