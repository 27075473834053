/**
 * View Board
 *
 * @description ::
 */
import Mustache from "mustache";

import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

export class ViewBoard extends HTMLElement {
  constructor(parent, model, isTemplate) {
    super();
    this.model = model;
    this.parent = parent;
    this.isTemplate = isTemplate;
    let styleSheet = document.createElement("style");
    styleSheet.textContent = style;
    this.locale = parent.locale;

    this.appendChild(styleSheet);
    this.model.onModelDeleted = () => this.onDelete();
  }

  connectedCallback() {
    this.initView(html);
  }

  initView(template) {
    this.innerHTML = Mustache.render(template, {
      userPlan: Global.user,
      model: this.model,
      locale: this.locale,
    });
    this.buttonDelete = this.querySelector(".button-delete");
    if (this.buttonDelete != null) {
      this.buttonDelete.addEventListener("click", (event) =>
        this.onButtonDeleteClick(event)
      );
    }
    this.buttonCopy = this.querySelector(".button-copy");
    if (this.buttonCopy != null) {
      this.buttonCopy.addEventListener("click", (event) =>
        this.onButtonCopyClick(event)
      );
    }
  }

  onButtonDeleteClick(event) {
    this.model.deleteModel();
  }

  onButtonCopyClick(event) {
    Global.firebaseFunctions
      .httpsCallable("CopyNewBoard")({
        fromId: this.model.attributes.id,
        toId: Global.appData.generateUID(),
        userId: Global.user.uid,
      })
      .then((result) => {
        console.log(result);
      });
  }

  onDelete() {
    this.remove();
  }

  disconnectedCallback() {}
}

customElements.define("view-board", ViewBoard);
