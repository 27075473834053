/**
 * Background Class
 *
 * @description ::
 */

import Konva from "konva";

export class WheelBackground {
  colors = {
    blue: "#1e87f0",
    red: "#ef709d",
    yellow: "#f0f757",
    green: "#bad29f",
    blue: "#1e87f0",
    red: "#ef709d",
    yellow: "#f0f757",
    green: "#bad29f",
  };

  constructor(container, elementName, layout) {
    this.container = container;
    this.layout = layout;
    this.elementName = elementName;
    this.initView();
  }

  initView() {
    this.stage = new Konva.Stage({
      container: this.container,
      width: this.layout.container.scrollWidth,
      height: this.layout.container.scrollHeight,
    });

    this.wheelLayer = new Konva.Layer({
      clipFunc: (ctx) => {
        ctx.beginPath();
        ctx.moveTo(
          this.layout.getWheelCenter().x + this.container.scrollLeft,
          this.layout.getWheelCenter().y + this.container.scrollTop
        );

        ctx.arc(
          this.layout.getWheelCenter().x + this.container.scrollLeft,
          this.layout.getWheelCenter().y + this.container.scrollTop,
          400,
          0 - Math.PI / 2,
          Math.PI + Math.PI / 2.5
        );
        ctx.lineTo(
          this.layout.getWheelCenter().x + this.container.scrollLeft,
          this.layout.getWheelCenter().y + this.container.scrollTop
        );
      },
    });
    this.postitLayer = new Konva.Layer();
    this.backgroundLayer = new Konva.Layer();

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.wheelLayer);
    this.stage.add(this.postitLayer);

    this.update();
  }

  update() {
    let containerWidth = this.layout.container.scrollWidth;
    let containerHeight = this.layout.container.scrollHeight;
    this.container.style.width = containerWidth + "px";
    this.container.style.height = containerHeight + "px";

    this.postitLayer.removeChildren();
    this.wheelLayer.removeChildren();
    this.backgroundLayer.removeChildren();

    this.layout.config.rings.forEach((ring, index) => {
      let dashArray, opacity;
      if (ring.isWheelTimeline) {
        dashArray = null;
        opacity = 1;
        var arrow = new Konva.Arrow({
          x: this.layout.getWheelCenter().x + this.container.scrollLeft,
          y:
            this.layout.getWheelCenter().y +
            this.container.scrollTop +
            ring.radius,
          points: [10, 0, 0, 0],
          pointerLength: 15,
          pointerWidth: 15,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });

        var arrow2 = new Konva.Arrow({
          x:
            this.layout.getWheelCenter().x +
            this.container.scrollLeft -
            ring.radius,
          y: this.layout.getWheelCenter().y + this.container.scrollTop,
          points: [0, 10, 0, 0],
          pointerLength: 15,
          pointerWidth: 15,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });

        var arrow3 = new Konva.Arrow({
          x:
            this.layout.getWheelCenter().x +
            this.container.scrollLeft +
            ring.radius,
          y: this.layout.getWheelCenter().y + this.container.scrollTop,
          points: [0, 0, 0, 10],
          pointerLength: 15,
          pointerWidth: 15,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });

        var circle = new Konva.Circle({
          x: this.layout.getWheelCenter().x + this.container.scrollLeft,
          y:
            this.layout.getWheelCenter().y +
            this.container.scrollTop -
            ring.radius,
          radius: 8,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });

        this.wheelLayer.add(arrow);
        this.wheelLayer.add(arrow2);
        this.wheelLayer.add(arrow3);
        this.postitLayer.add(circle);
      } else {
        dashArray = [8, 8];
        opacity = 0.5;
      }

      let ringBackground = new Konva.Circle({
        x: this.layout.getWheelCenter().x + this.container.scrollLeft,
        y: this.layout.getWheelCenter().y + this.container.scrollTop,
        radius: ring.radius,
        strokeWidth: 3,
        stroke: "black",
        dash: dashArray,
        opacity: opacity,
      });
      this.wheelLayer.add(ringBackground);
    });

    let itemsInsideWheel = this.layout.container.querySelectorAll(
      this.elementName + "[data-iswheeltimeline='true']"
    );
    this.sortedItems = [].slice.call(itemsInsideWheel);

    this.sortedItems.sort((a, b) =>
      a.model.attributes.wheelAngle > b.model.attributes.wheelAngle
        ? 1
        : b.model.attributes.wheelAngle > a.model.attributes.wheelAngle
        ? -1
        : 0
    );
    for (let index = 0; index < this.sortedItems.length; index++) {
      const element = this.sortedItems[index];
      const rect = element.getBoundingClientRect();

      let diffAngle;

      if (index < this.sortedItems.length - 1) {
        diffAngle =
          ((this.sortedItems[index + 1].model.attributes.wheelAngle -
            element.model.attributes.wheelAngle) *
            180) /
          Math.PI;
      } else {
        diffAngle =
          270.0 - (element.model.attributes.wheelAngle * 180.0) / Math.PI;
      }

      let backArc = new Konva.Arc({
        x: this.layout.getWheelCenter().x + this.container.scrollLeft,
        y: this.layout.getWheelCenter().y + this.container.scrollTop,
        innerRadius:
          this.layout.config.rings[element.model.attributes.ringIndex].radius -
          10,
        outerRadius:
          this.layout.config.rings[element.model.attributes.ringIndex].radius +
          10,
        angle: diffAngle,
        fill: this.colors[element.model.attributes.color],
        opacity: 1,
        rotation: (element.model.attributes.wheelAngle * 180.0) / Math.PI,
      });
      this.backgroundLayer.add(backArc);
    }

    this.onResize();
  }

  onResize() {
    var width = this.container.offsetWidth;
    var height = this.container.offsetHeight;
    this.stage.width(width);
    this.stage.height(height);
    this.backgroundLayer.draw();
    this.wheelLayer.draw();
    this.postitLayer.draw();
  }

  remove() {
    this.stage.destroy();
  }
}
