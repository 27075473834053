/**
 * Background Class
 *
 * @description ::
 */

import Konva from "konva";

export class TimelineBackground {
  colors = {
    blue: "#1e87f0",
    red: "#ef709d",
    yellow: "#f0f757",
    green: "#bad29f",
    blue: "#1e87f0",
    red: "#ef709d",
    yellow: "#f0f757",
    green: "#bad29f",
  };

  constructor(container, elementName, layout) {
    this.container = container;
    this.layout = layout;
    this.elementName = elementName;
    this.initView();
  }

  initView() {
    this.stage = new Konva.Stage({
      container: this.container,
      width: this.layout.container.scrollWidth,
      height: this.layout.container.scrollHeight,
    });

    this.timelineLayer = new Konva.Layer();
    this.postitLayer = new Konva.Layer();
    this.backgroundLayer = new Konva.Layer();

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.timelineLayer);
    this.stage.add(this.postitLayer);

    this.update();
  }

  update() {
    let containerWidth = this.layout.container.scrollWidth;
    let containerHeight = this.layout.container.offsetHeight;
    this.container.style.width = containerWidth + "px";
    this.container.style.height = containerHeight + "px";

    this.postitLayer.removeChildren();
    this.timelineLayer.removeChildren();
    this.backgroundLayer.removeChildren();

    this.totalHeight = this.layout.offsetTop;

    for (let index = 0; index < this.layout.config.timelines.length; index++) {
      const row = this.layout.config.timelines[index];
      var dashArray, opacity;
      if (row.isMainTimeline) {
        opacity = 1;
        var arrow = new Konva.Arrow({
          x: 220,
          y: this.totalHeight + row.rowHeight / 2,
          points: [0, 0, 10, 0],
          pointerLength: 15,
          pointerWidth: 15,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });
        var circle = new Konva.Circle({
          x: 20,
          y: this.totalHeight + row.rowHeight / 2,
          radius: 8,
          fill: "black",
          stroke: "black",
          strokeWidth: 3,
        });
        this.timelineLayer.add(circle);
        this.timelineLayer.add(arrow);
        dashArray = null;
      } else {
        opacity = 0.5;
        dashArray = [8, 8];
      }

      var timelineLine = new Konva.Line({
        points: [
          20,
          this.totalHeight + row.rowHeight / 2,
          this.container.scrollWidth,
          this.totalHeight + row.rowHeight / 2,
        ],
        stroke: "black",
        strokeWidth: 3,
        dash: dashArray,
        opacity: opacity,
      });

      this.timelineLayer.add(timelineLine);

      this.totalHeight += row.rowHeight;
    }

    let items = this.layout.container.querySelectorAll(
      this.elementName + "[data-ismaintimeline='true']"
    );

    this.sortedItems = [].slice.call(items);
    this.sortedItems.sort((a, b) =>
      a.model.attributes.positionX > b.model.attributes.positionX
        ? 1
        : b.model.attributes.positionX > a.model.attributes.positionX
        ? -1
        : 0
    );
    for (let index = 0; index < this.sortedItems.length; index++) {
      const element = this.sortedItems[index];
      const rect = element.getBoundingClientRect();
      var itemWidth = element.offsetWidth;

      if (index < this.sortedItems.length - 1) {
        let rect2 = this.sortedItems[index + 1].getBoundingClientRect();
        itemWidth =
          rect2.left +
          this.container.scrollLeft +
          (rect.left + this.layout.container.parentNode.scrollLeft);
      } else {
        itemWidth =
          this.container.scrollWidth +
          (rect.left + this.layout.container.parentNode.scrollLeft);
      }

      var timelineLine = new Konva.Line({
        points: [
          rect.left + this.layout.container.parentNode.scrollLeft + 15,
          rect.top + this.layout.container.parentNode.scrollTop - 25,
          rect.left +
            this.layout.container.parentNode.scrollLeft +
            itemWidth +
            15,
          rect.top + this.layout.container.parentNode.scrollTop - 25,
        ],
        stroke: this.colors[element.model.attributes.color],
        strokeWidth: 15,
      });

      this.backgroundLayer.add(timelineLine);
    }

    this.onResize();
  }

  onResize() {
    var width = this.container.offsetWidth;
    var height = this.container.offsetHeight;
    this.stage.width(width);
    this.stage.height(height);
    this.backgroundLayer.draw();
    this.timelineLayer.draw();
    this.postitLayer.draw();
  }

  remove() {
    this.stage.destroy();
  }
}
