import { Model } from "./model.js";

export class PostitModel extends Model {
  attributes = {
    text: "new postit",
    size: 1,
    template: "default",
    color: "yellow",
    label: "",
    index: 0,
    x: 0,
    y: 0,
    positionX: 0,
    positionY: 0,
    wheelAngle: 0,
    wheelQuarter: "right-top",
    itemDirection: "right",
    ringIndex: 0,
    isMainTimeline: false,
    isWheelTimeline: false,
    timelineRotation: "center",
    circles: [],
    icon: 0,
    mediaUrl: "",
    variant: "default",
  };

  constructor(referenceUrl, database, attributes, create) {
    super(referenceUrl, database, attributes, create);
    Object.assign(this.attributes, attributes);
  }
}
