/**
 * Draggable Class
 *
 * @description ::
 */

export class Draggable {
  isDragging = false;
  element = null;
  startDragX = 0;
  startDragY = 0;
  dragX = 0;
  dragY = 0;

  constructor(element, handle, parentElement, onDraggingEvent, onEndDragEvent) {
    this.element = element;
    this.handle = handle;
    this.parentElement = parentElement;
    this.onDraggingEvent = onDraggingEvent;
    this.onEndDragEvent = onEndDragEvent;

    this.onStartDragBind = this.onStartDrag.bind(this);
    this.onEndDragBind = this.onEndDrag.bind(this);
    this.onDraggingBind = this.onDragging.bind(this);

    this.isDragging = false;
    this.handle.addEventListener("mousedown", this.onStartDragBind, {
      passive: false,
    });

    this.handle.addEventListener("touchstart", this.onStartDragBind, {
      passive: false,
    });
  }

  onStartDrag(e) {
    e.preventDefault();
    this.isDragging = true;

    var x, y;
    if (e.touches) {
      var touch = e.touches[0];

      x = touch.pageX;
      y = touch.pageY;
    } else {
      x = e.clientX;
      y = e.clientY;
    }
    this.startDragX = x - this.element.offsetLeft;
    this.startDragY = y - this.element.offsetTop;

    document.addEventListener("touchend", this.onEndDragBind, {
      passive: false,
    });
    document.addEventListener("touchmove", this.onDraggingBind, {
      passive: false,
    });

    document.addEventListener("mouseup", this.onEndDragBind, {
      passive: false,
    });
    document.addEventListener("mousemove", this.onDraggingBind, {
      passive: false,
    });
  }

  onDragging(e) {
    this.element.classList.add("is-dragging");
    e.preventDefault();
    var x, y;
    if (e.touches) {
      var touch = e.touches[0];

      x = touch.pageX;
      y = touch.pageY;
    } else {
      x = e.clientX;
      y = e.clientY;
    }

    this.element.style.top = y - this.startDragY + "px";
    this.element.style.left = x - this.startDragX + "px";
    let parentBound = this.parentElement.getBoundingClientRect();
    let itemBound = this.element.getBoundingClientRect();
    let left = itemBound.left - parentBound.left;
    let top = itemBound.top - parentBound.top;

    let targetX = left + this.parentElement.scrollLeft;
    let targetY = top + this.parentElement.scrollTop;
    this.onDraggingEvent(targetX, targetY);
  }

  onEndDrag(e) {
    this.element.classList.remove("is-dragging");
    e.preventDefault();
    this.isDragging = false;

    document.removeEventListener("mousedown", this.onStartDragBind, {
      passive: false,
    });
    document.removeEventListener("touchstart", this.onStartDragBind, {
      passive: false,
    });

    document.removeEventListener("mouseup", this.onEndDragBind, {
      passive: false,
    });
    document.removeEventListener("touchend", this.onEndDragBind, {
      passive: false,
    });

    document.removeEventListener("touchmove", this.onDraggingBind, {
      passive: false,
    });
    document.removeEventListener("mousemove", this.onDraggingBind, {
      passive: false,
    });

    let parentBound = this.parentElement.getBoundingClientRect();
    let itemBound = this.element.getBoundingClientRect();
    let left = itemBound.left - parentBound.left;
    let top = itemBound.top - parentBound.top;
    let targetX = left + this.parentElement.scrollLeft;
    let targetY = top + this.parentElement.scrollTop;

    this.onEndDragEvent(targetX, targetY);
  }

  update() {}
}
