/**
 * AppDatastore class
 *
 * @description :: Class used to store and access web app data
 */
import firebase from "firebase/app";
import "firebase/database";

class AppDatastore {

    constructor(config, isDev) {

        this.app = firebase.initializeApp(config.firebaseConfig);
        this.database = firebase.database();
        if (isDev) {
            console.log ("is dev")
            this.database.useEmulator("localhost", 9000);
        }

    }

    getDataToJson(itemName) {
        return JSON.parse(this.getData(itemName));
    }

    getData(itemName) {
        return localStorage.getItem(itemName);
    }

    saveData(itemName, value) {
        localStorage.setItem(itemName, value);
    }

    async getFile(filePath) {
        let response = await fetch(filePath);
        let data = await response.text();
        return data;
    }

    async getJsonFile(filePath) {
        let response = await fetch(filePath);
        let data = await response.json();
        return data;
    }

    clearStore() {
        localStorage.clear();
    }

    generateUID() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

}

export default AppDatastore;