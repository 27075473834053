/**
 * Modal Share
 *
 * @description :: 
 */
import Mustache from "mustache";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class WheelModalShare extends HTMLElement {

    constructor(parent) {
        super();
        this.id = "modal-share";
        this.parent = parent;
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;
        this.appendChild(styleSheet);
        this.classList.add("modal-window");
        this.locale = parent.locale;
    }

    connectedCallback() {
        this.initView(html);

    }

    initView(template) {

        this.innerHTML = Mustache.render(template, {locale:this.locale});
        this.inputShare = this.querySelector("#input-share");
        this.inputShare.value = `${Global.appUrl}#/${this.locale.id}/wheelboard/${this.parent.boardId}`;
        this.hide();
        this.buttonClose = this.querySelector(".button-close");

        this.buttonClose.addEventListener('click', event => {

            this.hide();
        });
    }

    show() {
        this.style.display = "flex";

    }

    hide() {
        this.style.display = "none";
    }



    disconnectedCallback() {

    }

}

customElements.define('wheel-modal-share', WheelModalShare);