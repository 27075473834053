/**
 * boot.js
 *
 * @description :: Web app entry point
 */

import swal from "sweetalert";

import mainCss from "bundle-text:../css/main.css";
import bulmaCss from "bundle-text:../css/bulma.css";

import { Locale } from "../app/locale.js";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import Navigo from "navigo";

import { ViewSignup } from "./views/signup/controller.js";
import { ViewLogin } from "./views/login/controller.js";
import { ViewMain } from "./views/main/controller.js";
import { ViewMenuApp } from "./views/menuapp/controller.js";
import { ViewTos } from "./views/tos/controller.js";
import { ViewMainboard } from "./views/mainboard/controller.js";
import { ViewUpgrade } from "./views/upgrade/controller.js";

import AppDatastore from "./components/app-datastore.js";
import PaymentManager from "./components/payment-manager.js";
import ApiConnector from "./components/api-connector.js";

Global.bulmaSheet = new CSSStyleSheet();
Global.bulmaSheet.replace(bulmaCss);
Global.mainSheet = new CSSStyleSheet();
Global.mainSheet.replace(mainCss);

Global.router = new Navigo("/", { hash: true });
Global.locale = new Locale();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(new URL("../service-worker.js", import.meta.url))
    .then(function (registration) {})
    .catch(function (error) {
      console.log("Service worker registration failed, error:", error);
    });
}

if (window.location.hostname != "localhost") {
  Global.appData = new AppDatastore(
    {
      firebaseConfig: {
        apiKey: "AIzaSyDaukOR1Qdx3iI1jioOJvpdgOTzrwT3pfg",
        authDomain: "plotfixprod.firebaseapp.com",
        projectId: "plotfixprod",
        storageBucket: "plotfixprod.appspot.com",
        messagingSenderId: "473148332644",
        appId: "1:473148332644:web:4dbf07a49ecac4276cdd71",
        measurementId: "G-6VHE4VLWBF",
      },
    },
    false
  );

  Global.appUrl = "https://plotfix.app/";
  Global.firebaseFunctions = firebase.functions();
  Global.functionUrl = "https://us-central1-plotfixprod.cloudfunctions.net/";
  Global.firebaseApp = Global.appData.app;
} else {
  // DEV CONFIG

  Global.appData = new AppDatastore(
    {
      firebaseConfig: {
        apiKey: "AIzaSyDaukOR1Qdx3iI1jioOJvpdgOTzrwT3pfg",
        authDomain: "plotfixprod.firebaseapp.com",
        projectId: "plotfixprod",
        storageBucket: "plotfixprod.appspot.com",
        messagingSenderId: "473148332644",
        appId: "1:473148332644:web:4dbf07a49ecac4276cdd71",
        measurementId: "G-6VHE4VLWBF",
      },
    },
    true
  );
  Global.appUrl = "https://localhost:1234/";
  Global.firebaseFunctions = firebase.functions();
  Global.firebaseAuth = firebase.auth();

  Global.firebaseAuth.useEmulator("http://localhost:9099");
  Global.firebaseFunctions.useEmulator("localhost", 5001);
  Global.functionUrl = "http://localhost:5001/plotfixprod/us-central1/";

  Global.firebaseApp = Global.appData.app;
}

Global.paymentManager = new PaymentManager();
Global.apiConnector = new ApiConnector();

navigator.serviceWorker.onmessage = (event) => {};

// Html element where the main content of the app is inserted by the router
const mainDiv = document.querySelector("#main-div");
const menuDiv = document.querySelector("#menu-div");

Global.menuView = new ViewMenuApp({});
menuDiv.appendChild(Global.menuView);

var currentView = null;
Global.user = null;
Global.isFirstLoad = true;

firebase.auth().onAuthStateChanged(async function (user) {
  if (user) {
    // https://firebase.google.com/docs/reference/js/firebase.User
    Global.user = user;
    Global.currentSubscription = null;
  } else {
    Global.user = null;
  }
});

Global.getCurrentUser = function (auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

Global.changeView = async function (newView, option, needsLogin) {
  const user = await Global.getCurrentUser(firebase.auth());
  Global.user = user;
  if (Global.isFirstLoad) {
    Global.isFirstLoad = false;
  }
  if (Global.currentView) {
    mainDiv.removeChild(Global.currentView);
  }

  if (needsLogin) {
    if (user != null) {
      Global.currentView = new newView(option);
      mainDiv.appendChild(Global.currentView);
    } else {
      Global.currentView = new ViewLogin({
        requestedUrl: Global.appUrl,
        locale: option.locale,
      });
      mainDiv.appendChild(Global.currentView);
    }
  } else {
    Global.currentView = new newView(option);
    mainDiv.appendChild(Global.currentView);
  }
};

Global.router
  .on(/(.*)\/mainboard\/(.*)\/(.*)/, (match) => {
    Global.changeView(
      ViewMainboard,
      {
        boardId: match.data[2],
        boardLayout: match.data[1],
        locale: Global.locale[match.data[0]],
      },
      false
    );
  })
  .on("/fr", (match) => {
    Global.changeView(ViewMain, { locale: Global.locale["fr"] }, true);
  })
  .on("/", (match) => {
    Global.changeView(ViewMain, { locale: Global.locale["fr"] }, true);
  })
  .on("/en", (match) => {
    Global.changeView(ViewMain, { locale: Global.locale["en"] }, true);
  })
  .on(/(.*)\/login/, (match) => {
    Global.changeView(
      ViewLogin,
      { requestedUrl: Global.appUrl, locale: Global.locale[match.data[0]] },
      false
    );
  })
  .on(/(.*)\/logout/, (match) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        Global.changeView(
          ViewMain,
          { locale: Global.locale[match.data[0]] },
          true
        );
      })
      .catch((error) => {
        Global.changeView(
          ViewMain,
          { locale: Global.locale[match.data[0]] },
          true
        );
      });
  })
  .on(/(.*)\/signup/, (match) => {
    Global.changeView(
      ViewSignup,
      { locale: Global.locale[match.data[0]] },
      false
    );
  })
  .on(/(.*)\/upgrade\/(.*)/, (match) => {
    Global.changeView(
      ViewUpgrade,
      { result: match.data[1], locale: Global.locale[match.data[0]] },
      true
    );
  })
  .on(/(.*)\/tos/, (match) => {
    Global.changeView(ViewTos, { locale: Global.locale[match.data[0]] }, false);
  })
  .resolve();

Global.debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
