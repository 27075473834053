/**
 * View Signup
 *
 * @description :: View used to upgrade a new user account
 */
import Mustache from "mustache";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewUpgrade extends HTMLElement {

    constructor(options) {
        super();
        this.result = options.result;
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;

        this.appendChild(styleSheet);

    }

    connectedCallback() {
        this.initView(html);
    }


    initView(template) {
        this.innerHTML = Mustache.render(template, {});
        this.buttonBuy = this.querySelector("#button-buy-account");
        this.panelUpgraded = this.querySelector("#panel-upgraded");
        this.panelUpgrading = this.querySelector("#panel-upgrading");
        this.upgradeResult = this.querySelector("#upgrade-result");
        this.panelUpgraded.style.display = 'none';
        this.panelUpgrading.style.display = 'none';
        this.GetProducts ();
        this.GetPlan ();

        if (this.result == "success") {
            swal("Account upgraded", "Your are now a Pro user", "success");

            this.upgradeResult.innerHTML = `
            <article class="message is-success">
                <div class="message-body">
                Account upgraded.
                </div>
            </article>`;
        }
        if (this.result == "failed") {
            swal("Oups", "Something went wrong", "error");

            this.upgradeResult.innerHTML = `
            <article class="message is-warning">
                <div class="message-body">
                Something went wrong.
                </div>
            </article>`;
        }

        this.buttonBuy.addEventListener('click', event => {
            this.buttonBuy.disabled = true;
            Global.paymentManager.buyPlan ("price_1JPCEPGvrvl9G31dw5q9g7AJ", `${Global.appUrl}#/upgrade/success/`, `${Global.appUrl}#/upgrade/failed/`);
        });

    }


    async GetPlan () {
        Global.currentSubscription = await Global.paymentManager.getPlan ();
        if (Global.currentSubscription == null) {
            this.panelUpgraded.style.display = 'none';
            this.panelUpgrading.style.display = 'block';
        } else {
            this.panelUpgraded.style.display = 'block';
            this.panelUpgrading.style.display = 'none';
        }

        console.log (Global.currentSubscription);
    }

   async GetProducts () {
        console.log (await Global.paymentManager.getProducts ());
    }



    disconnectedCallback() {

    }

}

customElements.define('view-upgrade', ViewUpgrade);