/**
 * View Signup
 *
 * @description :: View used to create a new user
 */
import { CustomElement } from "../../components/custom-element";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewSignup extends CustomElement {

    constructor(options) {
        super();
        this.addPropreties (options);

    }

    initView() {

        this.setStyle (style);
        this.setSharedSheets ([Global.bulmaSheet, Global.mainSheet]);
        this.render (html, {locale:this.locale});

        this.bindElements ([
            ['#text-help', 'textHelp'],
            ['#input-password', 'inputPassword'],
            ['#input-email', 'inputEmail'],
            ['#button-create-account', 'buttonCreate']
        ]);

    }

    createAccount () {
        var email = this.inputEmail.value;
        var password = this.inputPassword.value;
        console.log (email);
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;
                window.location.assign(Global.appUrl);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                this.textHelp.innerHTML = errorMessage;
            });
    }


    disconnectedCallback() {

    }

}

customElements.define('view-signup', ViewSignup);