/**
 * View Wheelboard
 *
 * @description ::
 */

import { CustomElement } from "../../components/custom-element";

import { PostitModel } from "../../models/postit.js";
import { BoardModel } from "../../models/board";
import { BoardItemDefault } from "./item/templates/default/controller.js";
import { BoardItemMenu } from "./item/menu/controller.js";
import { Collection } from "../../models/collection.js";
import { WheelLayout } from "./layouts/wheel/layout.js";
import { TimelineLayout } from "./layouts/timeline/layout.js";
import { Navigator } from "./navigator/controller.js";

import { WheelModalShare } from "./modals/share/controller.js";

import html from "bundle-text:./template.html";
import style from "bundle-text:./template.css";

export class ViewMainboard extends CustomElement {
  boardId = null;
  isNewPostit = false;
  isShadowElement = true;
  config = {
    timelines: [
      {
        rowHeight: 80,
        color: "white",
        accept: "postit",
        readOnly: false,
        padding: 15,
        isFullWidth: false,
        layoutStyle: "full",
        background: "white",
        title: "II",
        columnWidth: 230,
      },
      {
        rowHeight: 80,
        color: "white",
        accept: "postit",
        readOnly: false,
        padding: 15,
        isFullWidth: false,
        layoutStyle: "full",
        background: "white",
        title: "I",
      },
      {
        rowHeight: 30,
        color: "white",
        accept: "postit",
        readOnly: false,
        padding: 0,
        isFullWidth: false,
        isMainTimeline: true,
        layoutStyle: "timeline",
        background: "white",
        title: "",
      },
      {
        rowHeight: 80,
        color: "white",
        accept: "postit",
        readOnly: false,
        padding: 15,
        isFullWidth: false,
        layoutStyle: "full",
        background: "white",
        title: "A",
      },
      {
        rowHeight: 80,
        color: "white",
        accept: "postit",
        readOnly: false,
        padding: 15,
        isFullWidth: false,
        layoutStyle: "full",
        background: "white",
        title: "B",
      },
    ],
    rings: [
      {
        radius: 320,
        color: "red",
        itemWidth: 230,
        itemHeight: 50,
        snapAngle: 50,
      },
      {
        radius: 260,
        color: "red",
        itemWidth: 230,
        itemHeight: 50,
        snapAngle: 50,
      },
      {
        radius: 200,
        color: "blue",
        itemWidth: 230,
        itemHeight: 50,
        snapAngle: 50,
        isWheelTimeline: true,
      },
    ],
    insideRadius: 200,
    insideItemWidth: 230,
    insideItemHeight: 30,
    insideSnapAngle: 30,
    insideRotate: false,
    insidreFreeRotate: false,
  };
  constructor(options) {
    super();
    this.addPropreties(options);
  }

  initView() {
    this.setStyle(style);
    this.setSharedSheets([Global.bulmaSheet, Global.mainSheet]);
    this.render(html, { userPlan: Global.user, locale: this.locale });

    this.model = new BoardModel(
      "boards/" + this.boardId,
      Global.appData.database,
      null,
      false
    );
    this.model.onValueUpdated = (newValue) => this.onValueUpdated(newValue);

    window.addEventListener("resize", (event) => this.onResize(event), false);
    this.addEventListener("elementupdate", (event) =>
      this.onElementUpdated(event)
    );

    this.bindElements([
      [".button-add", "buttonAdd"],
      [".menu-add", "addButtonContainer"],
      [".button-share", "buttonShare"],
      ["#board", "itemsList"],
      ["#board-container", "itemsListContainer"],
    ]);

    this.itemsListContainer.addEventListener("mousemove", (event) => {
      if (event.target.nodeName != "BOARD-ITEM") {
        let x =
          event.clientX +
          this.itemsListContainer.scrollLeft -
          this.itemsListContainer.offsetLeft;
        let y =
          event.clientY +
          this.itemsListContainer.scrollTop -
          this.itemsListContainer.offsetTop;

        this.layout.showMenutAt(x, y, this.addButtonContainer);
      } else {
        this.addButtonContainer.style.display = "none";
      }
    });

    this.itemsList.addEventListener("scroll", (event) => {
      this.boardItemMenu.hide();
    });

    this.boardItemMenu = new BoardItemMenu(this);
    this.mainRoot.appendChild(this.boardItemMenu);

    this.navigator = new Navigator(this.itemsListContainer, "board-item");
    this.mainRoot.appendChild(this.navigator);

    this.postitCollection = new Collection(
      `boards/${this.boardId}/postits`,
      Global.appData.database,
      (childKey, childData) => this.onPostitAdded(childKey, childData),
      "index"
    );

    this.modalShare = new WheelModalShare(this);
    this.changeLayout(this.boardLayout);

    this.mainRoot.appendChild(this.modalShare);
  }

  onValueUpdated(value) {
    this.itemsList.setAttribute("data-wheeltheme", this.model.attributes.theme);
    if (this.model.attributes.wheelOrientation == "angle") {
      this.layout.config.insideRotate = true;
    } else {
      this.layout.config.insideRotate = false;
    }
    this.layout.update();
    this.navigator.update();
  }

  clickMenuAdd(event) {
    this.createBoardItem();
  }

  createBoardItem() {
    var childKey = Global.appData.generateUID();

    if (this.boardLayout == "timeline") {
      console.log(this.layout.currentGridPosition);
      var childData = {
        positionX: this.layout.currentGridPosition.x,
        positionY: this.layout.currentGridPosition.y,
      };
      var newPostitModel = new PostitModel(
        `boards/${this.boardId}/postits/${childKey}`,
        Global.appData.database,
        childData,
        true
      );
      this.isNewPostit = true;
      newPostitModel.saveModel();
    } else {
      var childData = {
        wheelAngle: this.layout.currentWheelAngle,
        wheelPosition: this.layout.currentWheelPosition,
        ringIndex: this.layout.currentRingIndex,
      };
      var newPostitModel = new PostitModel(
        `boards/${this.boardId}/postits/${childKey}`,
        Global.appData.database,
        childData,
        true
      );
      this.isNewPostit = true;
      newPostitModel.saveModel();
    }
  }

  onPostitAdded(childKey, childData) {
    var newPostitModel = new PostitModel(
      `boards/${this.boardId}/postits/${childKey}`,
      Global.appData.database,
      childData
    );
    var newBoardItem = new BoardItemDefault(this, newPostitModel);
    this.itemsList.appendChild(newBoardItem);

    if (this.isNewPostit) {
      this.isNewPostit = false;
    }
    this.layout.update();
    this.navigator.update();
  }

  onButtonWheelBackgroundClick(event) {
    this.model.attributes.theme = event.target.dataset.wheeltheme;
    this.model.saveModel();
  }

  onButtonWheelOrientationClick(wheelorientation) {
    this.model.attributes.wheelOrientation = wheelorientation;
    this.model.saveModel();
  }

  changeLayout(layout) {
    this.boardLayout = layout;
    this.dataset.layout = layout;
    if (this.layout) {
      this.layout.remove();
    }

    if (layout == "wheel") {
      this.layout = new WheelLayout(this.itemsList, "board-item", this.config);
    } else {
      this.layout = new TimelineLayout(
        this.itemsList,
        "board-item",
        this.config
      );
    }
    this.layout.update();
  }

  onButtonChangeLayout(newLayout) {
    this.model.attributes.layout = newLayout;
    this.model.saveModel();
    this.changeLayout(newLayout);
  }

  onElementUpdated(event) {}

  onResize(event) {
    this.onElementUpdated(event);
    this.layout.update();
    this.layout.centerScroll();
    this.navigator.update();
  }

  disconnectedCallback() {
    this.postitCollection.disconnect();
  }
}

customElements.define("view-mainboard", ViewMainboard);
