/**
 * APIConnector class
 *
 * @description :: Class used to connect and send data to  API
 */

class APIConnector {


    async PostFunctionAsync (name, options) {

        const rawResponse = await fetch(Global.functionUrl + "CopyNewBoard", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':"*"
            },
            mode: 'cors',
            body: JSON.stringify(options)
          });
        const content = await rawResponse.json();
        
         return content;
    }

    async PostFunction (name, options, callback) {

    }


}

export default APIConnector;