import { Model } from "./model.js";

export class BoardModel extends Model {
    attributes = {
        text: "New Story",
        theme:"1",
        wheelOrientation:"straight", // angle
        id: "0",
        isTemplate: false,
    }

    constructor(referenceUrl, database, attributes, create) {

        super(referenceUrl, database, attributes, create);
        Object.assign(this.attributes, attributes);

    }
}